.searchInputs {
  /* margin-top: 105px; */
  display: flex;
}

.search input {
  background-color: white;
  border: 0;
  border-radius: 2px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  font-size: 18px;
  padding: 15px;
  height: 30px;
  width: 300px;
}

.searchIcon,
.contactSearchIcon {
  height: 10px;
  width: 20px;
  background-color: white;
  display: grid;
  place-items: center;
  position: absolute;
  right: 8%;
  margin-top: 18px;
}

.contactSearchIcon {
  right: 0%;
}

input:focus {
  outline: none;
}
.searchIcon svg {
  font-size: 20px;
}

.dataResult {
  margin-top: 5px;
  width: 300px;
  /* height: 200px; */
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  overflow: hidden;
  overflow-y: auto;
  padding: 10px;
}

.dataResult::-webkit-scrollbar {
  display: none;
}

.dataResult .dataItem {
  width: 100%;
  /* height: 50px; */
  display: flex;
  align-items: center;
  color: black;
}

.dataItem p {
  margin-left: 10px;
}
a {
  text-decoration: none;
}

a:hover {
  color: #cea05f !important;
  /* background-color: lightgrey; */
}

#clearBtn {
  cursor: pointer;
}

.bg-who-we-are-page{
    background-image: url('../../../../assests/images/who-we-are/who-we-are.png');
    background-size: cover;
    height: 40vh;
}
.bg-image-who-we-are{
    background-image: url('../../../../assests/images/who-we-are/image.png');
    background-size: cover;
    height: 80vh;
}

@media only screen and (max-width:600px){
    .bg-image-who-we-are{
        height:35vh !important;
    }
}

.slick-prev:before,.slick-next:before{
    color:#000000 !important;
}
.slick-prev:before, .slick-next:before {
    color: #000 !important;
    margin-left: 0px;
    /* background-color: #cea05f; */
    padding-top: 14px;
    padding-bottom: 10px;
}
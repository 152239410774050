html{
    scroll-behavior: smooth !important;
    font-family: 'Segoe UI' !important;
}
h1,h2,h3,h4,h5,h6{
    color:#000000 !important;
    font-family: 'Segoe UI' !important;
}
.height-fix-290{
    height: 290px !important;
}
.body-container{background-color: #fff;}
/**Desktop Query*/
@media only screen and (min-width: 768px) {
    .body-container{min-height: 600px;}
  }
  
  /*Tablet Query*/
  @media only screen and (min-width: 481px) and (max-width:768px) {
    .body-container{min-height: 485px;}
  }
  

.padding{
    padding:0px 30px 0px 30px;
    margin:10px;
    border-radius: 10px !important;
}
.padding1{
    padding:10px 10px 10px 10px;
    margin:10px;
    border-radius: 10px !important;
        }
.SubmitColor{
    background:#CEA05F !important;
    color:#fff !important;
    /* margin:10px !important; */
    float:right !important;
         }
.SubmitColor1{
    background:#9F7C50 !important;
    color:#fff !important;
    margin:10px !important;
}
.CancelColor{
    border-color:#CEA05F !important;
    color:#CEA05F !important;
    /* margin:10px !important; */
    /* float:right !important; */
         }

.registerSpace{
    margin:10px;
         }
.loginLink{
    font-weight:700;
    text-decoration:none;
    color:#9F7C50 !important;
         }
.notVendorLink{
           font-weight:700;
           text-decoration:none;
           color:#9F7C50 !important;
           padding: 10px;
         }
.notVendorLink1{
            font-weight:700;
            text-decoration:none;
            color:#9F7C50 !important;
            text-align: center !important;
          }
.notVendorLink:hover,.loginLink:hover{
         text-decoration: none !important;
}
.text-center{
        text-align: center !important;
        background: #383838;
        margin-bottom:15px;
        padding: 10px;
        /* border-top-right-radius:10px;
        border-top-left-radius:10px; */
        }
.text-center1{
    text-align: center !important;
}
.text-white{
    color:#fff !important;
    font-size : 14px;
}
.color-CEA05F{ font-size : 14px;}
.border-radius{
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
}
.color-p{
    color:#9F7C50 !important;
}
.width-100{
    width:100% !important;
}
.error-o{
    color:red !important;
}
.primary{
    background:#9F7C50 !important;
    color:#fff !important;
}
.space-half{
    padding: 5px !important;
}
.space-m-1{
    margin:10px !important;
}
.space-m-2{
    margin:20px !important;
}
.space-m-3{
    margin:30px !important;
}
.space-m-4{
    margin:40px !important;
}
.space-1{
    /* padding-bottom:30px !important; */
    padding: 10px;
}
.space-2{
    padding:20px !important;
}
.space-3{
    padding:30px !important;
}
.space-4{
    padding:40px !important;
}
.space-5{
    padding:50px !important;
}
.bold-600{
    font-weight: 600 !important;
}
.float-right{
    float: right !important;
}
.float-left{
    float: left !important;
}
.box-login-google{
    height:55px;
    background: #DD4B39;
    text-align: center;
    border-radius: 5px;
}
.box-login-google i{
    position: relative;
    top:15px;
    color:#fff !important;
    font-size: 24px;
}
.box-login-facebook{
    height:55px;
    background: #3B5998;
    text-align: center;
    border-radius: 5px;
}
.box-login-facebook i{
    position: relative;
    top:15px;
    color:#fff !important;
    font-size: 24px;
}
.box-login-twitter{
    height:55px;
    background: #c13584;
    text-align: center;
    border-radius: 5px;
}
.box-login-twitter i{
    position: relative;
    top:15px;
    color:#fff !important;
    font-size: 24px;
}
.box-login-pinterest{
    height:55px;
    background: #AC080C;
    text-align: center;
    border-radius: 5px;
}
.box-login-pinterest i{
    position: relative;
    top:15px;
    color:#fff !important;
    font-size: 24px;
}

.Footer-social-icons{
    float:right;
    position: relative;
    margin:0px !important;
}
.Footer-social-icons li{
    list-style-type: none;
    display: inline-block;
    padding: 0px 10px;
}
.color-white{
    color:#fff  !important;
}
.quick-links-left{
    float:left !important;
    padding:0px !important;
}
.quick-links-right{
    float:right !important;
    padding:0px !important;
}
.quick-links-left li{
    list-style-type: none;
    padding: 7px 0px;
}
.quick-links-left li a{
    color:#383838 !important;
    text-decoration: none !important;
    font-size: 16px;
}
.quick-links-left li a:hover{
    text-decoration: none !important;
}
.quick-links-left li i{
    color:#CEA05F !important;
    font-size: 18px;
}
.font-footer-content{
    font-size: 0.875rem;
}
.newsletter-image-left li{
float:left !important;
}
.padding-input{
    padding:10px !important;
}
.MuiOutlinedInput-multiline{
    /* padding:8px !important; */
    /* margin:8px 0px !important; */
}
.space-bottom{
    margin-bottom:10px !important;
}

.footermain{
    background:#414446;
    height: 80px;
    /* padding:15px 0px; */
    overflow:hidden;
    display: flex;
    align-items: center;
    width: 100%;
    /* position: fixed;
    bottom: 0;
    width: 100%; */
}
.font-size-12{
    font-size: 12px !important;
}
.font-size-13{
    font-size: 13px !important;
}
.font-size-14{
    font-size: 14px !important;
}
.font-size-15{
    font-size: 15px !important;
}
.font-size-16{
    font-size: 16px !important;
}
.font-size-17{
    font-size: 17px !important;
}
.font-size-18{
    font-size: 18px !important;
}
.font-size-19{
    font-size: 19px !important;
}
.font-size-20{
    font-size: 20px !important;
}
.font-size-22{
    font-size: 22px !important;
}
.font-size-24{
    font-size: 24px !important;
}
.font-size-26{
    font-size: 26px !important;
}
.font-size-11{
    font-size: 11px !important;
}
.font-size-12{
    font-size: 12px !important;
}
.font-size-13{
    font-size: 13px !important;
}
.space-t-5{
    padding-top:5px !important;
    padding-bottom:5px !important;
}
.space-t-7{
    padding-top:7px !important;
    padding-bottom:7px !important;
}
.form-width-100{
    width:100% !important;
}
.form-width-60{
    width:60% !important;
}
.padding-20{
    padding:20px !important;
}
.margin-bottom-15{
    margin-bottom: 18px !important;
}
.bg-white{
    background: #fff !important;
}
.text-align-justify{
    text-align: justify !important;
}
.button-bg-color{
    background: #CEA05F !important;
    color: #ffffff !important;
}
.button-bg-color2{
    color:#CEA05F !important;
}
.BIlling-Address{
    position: relative;
    right: 0px;
    top: -60px;
}
.BIlling-Address ul{
    list-style-type: none !important;
}
.BIlling-Address ul li{
    float: right !important;
    padding:15px !important;
}
.address ul{
    list-style-type: none !important;
    padding: 0px !important;
    margin:0px !important;
}
.address ul li{
    font-size: 16px !important;
    font-weight: 400 !important;
    color:#AAAAAA !important;
}
.Address{
    position: relative;
    right: 0px;
    top: -30px;
}
.Address ul{
    list-style-type: none !important;
}
.Address ul li{
    float: right !important;
    padding:15px !important;
    color:#AAAAAA !important;
}
.colorAAAAAA{
    color:#AAAAAA !important;
}
.space-box{
    width:20px !important;
    height:20px !important;
    background: #AAAAAA !important;
}
.SubmitColor2{
    background: #CEA05F !important;
    padding: 10px 0px !important;
    color:#ffffff !important;
}
.box-0{
    list-style-type: none !important;
    padding: 0px !important;
}
.box-0 li{
   display: inline-block !important;
   padding: 12px 5px !important;
   font-size: 14px !important;
}

/* Profile pic Start*/
    .profile-pic ul{ 
        list-style-type: none !important;
    }
    .profile-pic ul li{ 
        float: left !important;
        /* padding: 20px 25px !important; */
    }
    .profile-Details{
        text-align: center !important;
    }
    /* .profile-Details{

    } */
    .userdetails ul{
        list-style-type: none !important;
        margin: 0px !important;
        padding: 10px !important;
    }
    .userdetails ul li{
        padding: 5px 0px !important;
        /* height: 26px !important; */
    }
    .userdetails ul li a{
        padding: 0px 10px !important;
    }
    .userdetails ul li a div{
        font-size: 14px !important;
        font-weight: 500 !important;
        /* padding: 0px 10px !important; */
    }
    .profile-Button{
        background: transparent linear-gradient(90deg,#D0A160 0%, #E8B46B 100%) 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #CAAB74;
        border: 1px solid #FFFFFF;
        opacity: 1;
        color: #fff !important;
    }
/* Profile pic End */
.MuiSwitch-colorSecondary.Mui-checked{
    color: #CEA05F !important;
}
.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track{
    background-color: #CEA05F !important;
}
.MuiStepLabel-completed{
    color: #CEA05F !important;
}
.MuiPopover-paper-88{
    top:80px !important;
}
.form-width-30{
    width: 30% !important;
}
.form-width-50{
    width: 50% !important;
}
.form-width-65{
    width: 65% !important;
}
.min-height-400{
    min-height: 400px !important;
    clear: both !important;
}
.overflow-400px{
    height: 400px !important;
}
@media only screen and (max-width:768px){
    .min-height-400{
        min-height: auto !important;
    }
    .overflow-400px{
        height: 400px !important;
        overflow:scroll !important;
    }
}
.alignment-2:hover{
    /* height:330px !important; */
    cursor: pointer;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);

}
/* <div>
<Link aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} style={{display:'flex'}}>
  <img src={icon} alt="user" width="20px" />
  null
  {{localStorage.getItem('Firstname')+localStorage.getItem('Lastname')}}
</Link>
<Menu
  id="simple-menu"
  anchorEl={anchorEl}
  keepMounted
  open={Boolean(anchorEl)}
  onClose={handleClose}
>
  <MenuItem onClick={handleClose}>Profile</MenuItem>
  <MenuItem onClick={handleClose}>My account</MenuItem>
  <MenuItem ><Link onClick={()=>{localStorage.removeItem("Firstname");localStorage.removeItem("Lastname");localStorage.removeItem("userId");localStorage.removeItem("Token");localStorage.removeItem("CountryId");localStorage.removeItem("State");localStorage.removeItem("City");}} href="/">Logout</Link></MenuItem>
</Menu>
</div> */

.bg-image-iindex{
    background-image: url('../../assests/images/bgimage.png');
    background-size: cover;
    height:70vh;
    margin-bottom: 20px !important;
}
.MuiMenu-list{
    /* max-width:250px !important; */

  
}
.imput-height{
    padding:10px !important;
    margin-top:20px !important;
}
.MuiSlider-root{
    color:#CEA05F !important;
}
/* .MuiPaper-root 
{
/* opacity: 1;
 transform: none; 
 transition: opacity 314ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 209ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; 
 top: 16px; 
 left: 208px; 
 transform-origin: 195px 14px; */


/* .ul-MuiList-root-121-MuiMenu-list-108-MuiList-padding-122{
    background-color: black;
} */

@media screen and (max-width: 1071px){
    .topnav{
      font-weight: 10px;
      color: rgba(0, 0, 0, 0.87) !important;
      transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
    }
    .text-white,.color-CEA05F{
        font-size:12px !important;

    }

  }
  @media screen and (max-width: 560px){
.footer{
    text-align: center;
}
  }
  .MuiPaper-root-151 {
   
    /* background-color: #fff; */
}
@media screen and (max-width: 768px){
.menu1{
  
    top:250px !important;
    
}
}
@media screen and (max-width: 360px){
    .menu1{
      
        top:340px !important;
        
    }
    }
    .MuiList-padding {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }
.bg-image-browse-watches {
  background-image: url("../utils/bgimage.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 55vh;
  background-position: center;
}
.bg-image-browse-watches::after {
  background: #000000 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  opacity: 1;
}
.content-browse-watches {
  padding: 9% 0px !important;
}
.bg-input {
  margin: 0px !important;
  background: #fff;
  width: 19% !important;
  /* border-radius: 5px !important; */
  padding: 0px 8px 0px !important;
}
.primary-text {
  color: #cea05f !important;
}
.MuiPagination-ul li {
  margin: 0 auto !important;
}
.alignment-6 {
  margin: 0px !important;
  width: 25% !important;
}
.MuiInput-underline:after {
  border-bottom: none !important;
}
.MuiButton-outlined {
  padding: 4px 15px !important;
}
.MuiCardMedia-img {
  object-fit: scale-down !important;
}
.MuiOutlinedInput-input {
  width: 100% !important;
}
.button-slider2 {
  border-radius: 0px !important;
}
.cardtransform {
  text-transform: capitalize !important;
}

/* Responsiveness of the Page Start*/
@media only screen and (max-width: 1095px) {
  .bg-image-browse-watches {
    height: 55vh !important;
  }
  .MuiOutlinedInput-multiline {
    height: auto !important;
  }
  .bg-input {
    width: 36% !important;
  }
  .content-browse-watches {
    padding: 10% 0% !important;
  }
  .padding1 {
    padding: 10px 0px 10px 0px !important;
  }
}

@media only screen and (max-width: 756px) {
  .resposive-align-1 {
    font-size: 1.25rem !important;
  }
  .bg-image-browse-watches {
    height: 65vh !important;
  }
  .content-browse-watches {
    padding: 20% 0% !important;
  }
  .bg-input {
    width: 42% !important;
  }
}
/* Responsiveness of the Page End*/

.formControl {
  margin: 15px !important;
  width: 100% !important;
  margin: 0px !important;
}
.font-14 {
  font-size: 0.7rem !important;
  color: #cea05f !important;
}
.MuiTypography-textupper {
  text-transform: uppercase !important;
}
.MuiTypography-body2 {
  text-transform: capitalize !important;
}
.sentense {
  color: #000 !important;
  position: fixed;
  bottom: 15px;
  right: 10px;
  background: #cea05f;
  padding: 0px 13px;
  height: 53px;
  border-radius: 28px;
  cursor: pointer;
  box-shadow: 1px 2px 0px 1px #ccc;
}
@media only screen and (max-width: 777px) {
  .sentense {
    color: #000 !important;
    position: fixed;
    bottom: 15px;
    right: 10px;
    background: #cea05f;
    padding: 0px 13px;
    height: 53px;
    border-radius: 28px;
    cursor: pointer;
    box-shadow: 1px 2px 0px 1px #ccc;
  }
}
@media only screen and (min-width: 1095px) {
  .filter {
    width: calc(100% + 220px) !important;
  }
}
.MuiGrid-spacing-xs-4 {
  width: calc(100% + 14px) !important;
  margin: -7px !important;
}

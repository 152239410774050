.MuiTab-wrapper {
  display: contents !important;
}
.MuiTab-textColorInherit.Mui-selected {
  background: #cea05f !important;
  color: #fff !important;
}
.PrivateTabIndicator-colorSecondary-107 {
  background-color: #fff !important;
}
.width-100 {
  width: 100% !important;
}
.MuiTab-root {
  max-width: 100% !important;
}
.MuiBox-root-152 {
  padding: 10px !important;
}
.css-41abqd-MuiTableContainer-root {
  max-height: none;
}
.MuiDialogTitle-root {
  padding-bottom: 0px !important;
}
.section-chart {
  margin: 20px !important;
  width: 200px !important;
  height: 200px !important;
  /* border:2px solid #CEA05F !important;
    border-radius: 50px !important; */
}
.space-marign-10 {
  margin-top: 10px !important;
}
.chart-section {
  position: relative;
  top: 24px !important;
  left: 29px !important;
}
.height-135 {
  height: 135px !important;
}
.alignment-10 {
  height: 590px !important;
  overflow: auto !important;
}
.section-chart-sold {
  position: relative;
  top: 30px !important;
}
.margin-center {
  margin: 0 auto !important;
}
.margin-120 {
  margin: 0px 120px !important;
}
.margin-110 {
  margin: 10px 110px !important;
}

@media only screen and (max-width: 1024px) {
  .height-135 {
    height: 100px !important;
  }
  .section-chart {
    margin: 10px !important;
    width: 55px !important;
    height: 56px !important;
    border: 2px solid #cea05f !important;
    border-radius: 50px !important;
  }
  .chart-section {
    position: relative;
    top: 15px !important;
    left: 15px !important;
  }
  .section-chart-sold {
    top: 15px !important;
  }
  .margin-120 {
    margin: 0px 50px !important;
  }
  .margin-110 {
    margin: 10px 40px !important;
  }
}

@media only screen and (max-width: 956px) {
  .overflow-400px {
    height: 450px !important;
    overflow: auto !important;
  }
}

@media only screen and (max-width: 768px) {
  .margin-120 {
    margin: 0px 20px !important;
  }
  .margin-110 {
    margin: 10px 10px !important;
  }
}

@media only screen and (max-width: 599px) {
  .overflow-400px {
    height: 450px !important;
    overflow: auto !important;
  }
  .alignment-10 {
    height: 350px !important;
    overflow: auto !important;
  }
  .margin-120 {
    margin: 0px 20px !important;
  }
  .form-width-50 {
    width: 100% !important;
  }
  .chart-image {
  }
}

.canvasjs-chart-canvas {
  height: 310px !important;
  width: 100% !important;
}
@media only screen and (max-width: 590px) {
  .inventrybox {
    /* height:530px; */
    word-break: break-word;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  }
  .inventrybox:hover {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  }
}
.video-player__video {
  margin-top: 1rem;
  max-width: 100%;
  margin: 0 auto;
  height: 250px;
  width: 350px;
}

.video-player__thumbnail-container {
  display: none !important;
}

.img-width-22 {
  width: 350px !important;
  height: 350px !important;
  object-fit: contain !important;
}
.img-width-23 {
  width: 350px !important;
  height: 350px !important;
  object-fit: contain !important;
}
.MuiGrid-spacing-xs-4 {
  width: calc(100% + 32px);
  margin: -20px;
}
.cardtransform {
  text-transform: capitalize !important;
}
.inventrybox {
  border: 1px solid #ccc;
  /* height: 300px !important;  */
}
#upform {
  margin-top: 0px !important;
}
.MuiAlert-icon {
  display: flex;
  opacity: 0.9;
  padding: 21px 0 !important;
  font-size: 22px;
  margin-right: 12px;
}
.font-14 {
  color: black !important;
  font-weight: 100px;
}
.update {
  margin-top: 0px !important;
}
.slick-prev {
  left: -19px;
}
@media only screen and (min-width: 780px) {
  .invdetail {
    margin-left: 185px !important;
  }
}

@media only screen and (min-width: 360px) {
  .img-width-22 {
    width: 255px !important;
    height: 350px !important;
    object-fit: contain !important;
  }
}
/* mobile and tab screen */
@media only screen and (min-width: 360px) {
  .slick-next:before {
    color: #000 !important;
    margin-left: -12px !important;
    /* background-color: #cea05f; */
    padding-top: 14px;
    padding-bottom: 10px;
  }
}
.section-label {
  margin-bottom: 10px;
  background-color: #f5f5f6;
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  padding: 10px 25px;
  font-size: 22px;
}

.section-input {
  margin-bottom: 10px;
  display: flex;
  font-weight: bold;
  padding: 10px 25px;
  align-items: center;
  font-size: 18px;
}
.MuiTableContainer-root {
  max-height: none !important;
}

.MuiTableSortLabel-icon {
  color: #fff !important;
}

.void-status {
  color: rgb(255, 255, 255) !important;
  background-color: rgb(211, 47, 47) !important;
}

.active-status {
  color: rgb(255, 255, 255) !important;
  background-color: rgb(46, 125, 50) !important;
}

.ScrollUpButton__Toggled {
  animation: bounce2 2s ease infinite;
}

@keyframes bounce2 {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

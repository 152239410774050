/* Contact Us Banner start */
.banner-contactus-component{
    background-image: url('../../../../assests/images/Group\ 1789.png');
    background-size: cover;
    background-repeat: no-repeat;
    height:50vh !important;
}
.padding-10{
    padding: 10px 14px !important;
}
/* Contact Us Banner end */

/* love watches component start */
.social-icons{
    list-style-type: none !important;
    margin:0px !important;
    padding:0px !important;
}
.social-icons li{
    float: left !important;
    padding-right: 15px !important;
}
.social-icons-contact-us{
    background: #CEA05F !important;
    width: 40px  !important;
    height: 40px !important;
    border-radius: 25px !important;
    margin: 20 auto !important;
    float:left !important;
}
.social-icons-contact-us i{
    position: relative;
    top: 10px;
    left: 10px;
    font-size: 18px;
    color:#fff !important;
}
/* love watches component end */

.bg-contact-us-form-submit-button{
    background: #CEA05F !important;
    width: 100% !important;
}

.border-contact-form{
    border:0.5px solid #CEA05F !important;
    width:8% !important; 
}
.border-love-watches-component{
    border:0.5px solid #9F7C50 !important;
    margin:0 auto !important;
    width:45% !important;
}

.background-blur{
    background: transparent !important;
}

/* media Quries  Start */
@media only screen and (max-width:768px){
    .space-5{
        padding:20px !important;
    }
    .space-3{
        padding:20px !important;
    }
}

@media only screen and (max-width:600px){
    .space-5{
        padding:10px !important;
    }
}

@media only screen and (max-width:960px) and (min-width:426px){
    .border-love-watches-component{
        border:0.5px solid #9F7C50 !important;
        margin:0 auto !important;
        width:35% !important;
    }
}
/* media Quries  End */

/* banner-dealer-background-image start */
.banner-dealer-background-image{
    background-image: url('../../../../assests/images/dealer-bg.png');
    background-size: contain;
    background-repeat: no-repeat;
    height:73vh !important;
}
.banner-dealer-content{
    list-style-type: none !important;
    padding-inline-start: 0px !important;
}
.banner-dealer-content li{
    padding:5px;
}
.banner-dealer-content li ul{
    list-style-type: none;
    padding: 0px !important;
}
.banner-dealer-content li ul li{
    float: left;
    font-size: 13px;
    text-align: center;
    padding-right: 20px;
}
.margin-space{
    margin:0 auto !important;
}
.banner-dealer-button-color{
    background: #CEA05F !important;
    padding: 10px 100px !important;
    color: #fff !important;
    cursor: pointer;
}
.button-dealer-outlined{
    background: transparent;
    color: #CEA05F !important;
    border:1px solid #CEA05F !important;
}
.Arrow-right::after{
    content: url('../../../../assests/images/Path\ 2506.svg');
    position: relative;
    left:70px;
    top:5px;
}
/* banner-dealer-background-image end */

/* test-us-free-background-color start */
.test-us-free-background-color{
    background:url('../../../../assests/images/bg-dealer.png');
    background-size:cover;
    background-repeat: no-repeat;
}
.test-us-free-button-color{
    background: #CEA05F !important;
    padding: 10px 80px !important;
    color: #fff !important;
}

/* test-us-free-background-color end */

/* Form start */
.form-width-70{
    width:70% !important;
    /* margin-bottom: 20px !important; */
}
.form-width-85 {
    width:85% !important;
}

.form-width-99 {
    width:99% !important;
}

.form-field{
    margin-bottom: 20px !important;

}

.form-width-0-70{
    width:70% !important;
}
.MuiFormControlLabel-root{
    font-size: 16px !important;
}

.MuiStepLabel-completed{
    color: #CEA05F !important;
}

.MuiStepLabel-active{
    color: #CEA05F !important;
}


/* Dealer business details start */
.MuiStepLabel-label{
    color:#fff ;
}
.MuiStepIcon-root.MuiStepIcon-active{
    color: #CEA05F !important;
}
.MuiStepIcon-root.MuiStepIcon-completed{
    color:#CEA05F !important;
}
.MuiStepLabel-label.MuiStepLabel-alternativeLabel{
    color:#fff ;
}
.uppercase{
    text-transform: uppercase !important;
}
.MuiCheckbox-colorSecondary.Mui-checked{
    color: #CEA05F !important;
}
.Button-outline-border{
    background:#fff !important;
    color:#333333 !important;
    border:1px solid #333333 !important;
}
.Button-outline-border-primary{
    background:#CEA05F !important;
    color:#fff !important;
    border:1px solid #CEA05F !important;
}
/* Dealer business details end */

@media only screen and (max-width:1230px){
    .banner-dealer-background-image{
        height:auto !important;
        background-size:cover !important;
    }
}
@media only screen and (max-width:576px){
    .banner-dealer-button-color{
        padding: 10px 10px !important;
    }
}
@media only screen and (max-width:376px){
    .banner-dealer-button-color{
        font-size: 10px !important;
    }
}



/* dealers product detail component  */
.MuiSlider-thumb{
    width:25px !important;
    height: 25px !important;
    background:#cecece !important;
    margin-top: -11px !important;
}
.margin-t-b-20{
    margin:20x 0px !important;
}
.margin-p-b-20{
    padding:20x 0px !important;
}
.margin-b-15{
    margin-bottom:15px !important;
}

.input-upload{
    display: none;
}
.Primary-Border{
    border:2px solid #CEA05F !important;
}
.primary-border-dashed{
    border:2px dashed #707070 !important;
}
.width-70{
    width: 70% !important;
}
.width-80{
    width: 80% !important;
}
.width-90{
    width: 90% !important;
}
.width-100{
    width: 100% !important;
}
.iframe-video{
    width: 600px !important;
    height: 350px !important;
}

.image-shopping{
    width:120px !important;
    /* float: right !important;  */
    margin:0 auto !important;
}
.image-shopping-right{
    width:80px !important;
    float: right !important; 
}
.image-shopping-left{
    width:80px !important;
    float: left !important; 
}
.image-shopping1{
    margin-top: 8px !important;
}
.img-dim {
    text-align:center !important;
    /* margin-left: 226px !important; */
   
    width: 120px !important;
   
}


@media only screen and (max-width:1024px){
    .iframe-video{
        width: 100% !important;
        height: auto !important;
    }
}

@media only screen and (max-width:599px){
    .space-3{
        padding:30px 0px !important;
    }
    .space-left-30{
        padding-left: 0px !important;
    }
    .form-width-70{
        width:100% !important;
    }
    .form-width-0-70{
        width: 100% !important;
    }
    .space-hide{
        display: none !important;
    }
    .width-80{
        width:100% !important;
    }
    .space-3-hide{
        padding:30px !important;
    }
} 


.MuiStepper-alternativeLabel{
    background: #373636 !important;
}
.Checked{
    position: relative !important;
    width: 5px;
    float: right;
    position: relative !important;
    top: -32px;
    right: 27px;
}
.Checked i{
    color: #CEA05F !important;
    font-size: 26px !important;
    position: relative;
    left: 97%;
    top: 23px;
}
.UnChecked{
    display: none !important;
}
   
.expand{
    transform: rotate(0deg) !important;
    margin-left: auto;
}
.expandOpen{
    transform: rotate(270deg) !important;
  }

  .activePackage{
   box-shadow: 1px 1px 30px #c8c8c8 !important;
  }
  .activePackage1{
    box-sizing: border-box;
    border: 3px solid #CEA05F;
   }

   .MuiSlider-valueLabel span:nth-child(1) {
    height: 45px;
    width: 45px;
    padding-top: 12px;
  margin-left:11px;
    margin-top: 5px;
  }
  .MuiTypography-body1{
    margin-left: 330px;
    font-weight: 400 !important;
    font-size: .72rem !important;
    color: black  !important;
  }
  .ebayAmount{
      margin-left: 30px;
  }
  #search{
    /* margin-left:20px !important; */
    padding: 27px 16px !important;
  }
  .MuiFormControl-root {
    border: 0;
    margin: 0;
    /* display: inline-flex; */
    padding: 0;
    position: relative;
    min-width: 0;
    flex-direction: column;
    vertical-align: top;
}
.intakeform{
    border: 0;
    margin: 0;
    /* display: inline-flex; */
    padding: 0;
    position: relative;
    min-width: 0;
    flex-direction: column;
    vertical-align: top;
}
.MuiGrid-spacing-xs-4 {
    width: calc(100% + 32px);
    margin: -40px;
}

.MuiSlider-markLabel {
    top: 26px;
    color:black !important;
    position: absolute;
    font-size: 0.72rem !important;
    transform: translateX(-50%);
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 100;
    line-height: 1.43;
    white-space: nowrap;
    letter-spacing: 0.01071em;
}
.bodyloader {
    color: rgba(0, 0, 0, 0.87);
    margin: 0;
    font-size: 0.025rem !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.43;
    letter-spacing: 0.01071em;
   
}
/* .label + .MuiInput-formControl {

    margin-top: 5px !important;
} */
/* .label {
    margin-top: 5px !important;
} */
#upform{
    margin-top: 5px !important;
}
/* .MuiInput-formControl { 
     margin-top: 5px !important;
} */
@media only screen and (max-width:768px){
    .eby{
        margin-left: 128px !important;
    }
    
}
@media only screen and (min-width:768px){
    .img-dim {
       
         margin-left: 230px !important;
       
        width: 120px !important;
       
    }
}
@media only screen and (min-width:1020px){
    .img-dim {
       
        margin-left: 95px !important;
       
        width: 120px !important;
       
    }
}

#form-file-upload,
#form-file-upload2,
#form-file-upload3,
#form-file-upload4,
#form-file-upload5,
#form-file-upload6,
#form-file-upload7,
#form-file-upload8 {
  height: 16rem;
  width: 28rem;
  max-width: 100%;
  text-align: center;
  position: relative;
  margin-top: 15px;
}
#form-file-upload9{
  max-width: 100%;
  text-align: center;
  position: relative;
  margin-top: 15px;
}
#input-file-upload,
#input-file-upload2,
#input-file-upload3,
#input-file-upload4,
#input-file-upload5,
#input-file-upload6,
#input-file-upload7,
#input-file-upload8,
#input-file-upload9 {
  display: none;
}

#label-file-upload,
#label-file-upload2,
#label-file-upload3,
#label-file-upload4,
#label-file-upload5,
#label-file-upload6,
#label-file-upload7,
#label-file-upload8, #label-file-upload8 {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#label-file-upload.drag-active,
#label-file-upload2.drag-active,
#label-file-upload3.drag-active,
#label-file-upload4.drag-active,
#label-file-upload5.drag-active,
#label-file-upload6.drag-active,
#label-file-upload7.drag-active,
#label-file-upload9.drag-active,
#label-file-upload8.drag-active {
  background-color: #ffffff59;
}

.drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.height-100{
    height:100vh !important;
}
.height-75{
    height:100vh !important;
}
.height-50{
    height:100vh !important;
}
.height-25{
    height:100vh !important;
}
.width-100{
    width: 100% !important;
}
.width-75{
    width: 100% !important;
}
.width-50{
    width: 100% !important;
}
.width-25{
    width: 100% !important;
}
.pt-1{
    padding-top:10px !important;
}
.font-weight-100{
    font-weight:100 !important;
}
.font-weight-200{
    font-weight:200 !important;
}
.font-weight-300{
    font-weight:300 !important;
}
.font-weight-400{
    font-weight:400 !important;
}
.font-weight-500{
    font-weight:500 !important;
}
.font-weight-600{
    font-weight:600 !important;
}
.font-weight-700{
    font-weight:700 !important;
}
.font-weight-800{
    font-weight:800 !important;
}
.pb-1{
padding-bottom: 10px !important;
}
.underline-0{
    border-bottom:2px solid #9F7C50 !important;
    width:30px !important;
    padding-top:5px !important;
    padding-bottom:5px !important;
    margin-bottom:5px !important;
}
.img-resonsive{
    width: 100% !important;
    overflow: hidden !important;
    height: 80vh !important;
}
.position-text-banner{
    position: absolute;
    top:25% !important;
    padding: 0px 35px !important;
    margin: 0px 150px !important;
    border-left: 4px solid #fff;
    color:#fff !important;
}
.position-text-banner1{
    position: absolute;
    top:75% !important;
    padding: 0px 35px !important;
    margin: 0px 50px !important;
    color:#fff !important;
}
.bg-card-watch-lovers-color{
    width: 100% !important;
}
.img-responsive {
    display: block;
    max-width: 100%;
    height: auto;
    margin: 0 auto !important;
}
.button-slider{
    background: #CEA05F !important;
    color: #fff !important;
    border:2px solid #fff !important;
}
.button-slider1{
    background: transparent !important;
    color: #fff !important;
    border:2px solid #fff !important;
    margin-left: 40px !important;
}
.button-slider2{
    background: #CEA05F !important;
    color: #fff !important;
    position: relative;
    top:-1px;
    left:-16px;
}
.button-slider3{
    background: #CEA05F !important;
    color: #fff !important;
    position: relative;
    top:-1px;
    left:-5px;
}
.MuiOutlinedInput-inputMarginDense {
    padding-top: 3.5px !important;
    padding-bottom: 10.5px !important;
}
.button-slider:hover{
    background: transparent !important;
    border:2px solid #fff !important;
    color: #fff !important;
}
.button-slider1:hover{
    background: #CEA05F !important;
    color: #fff !important;
    border:2px solid #fff !important;
    margin-left: 40px !important;
}
.media{
    /* padding: 20px 20px; */
    height: 80px;
    width: 80px;
    margin:20px auto !important;
  }
  .img-dimen{
      
  }
  .text-wrap{
      font-size: 20px !important;
      font-weight: 700 !important;
      word-wrap: break-word !important;
  }
.text-wrap1{
    font-size: 12px !important;
    font-weight: 700 !important;
    word-wrap: break-word !important;
}
.padding3{
padding:10px !important;
margin: 20px !important;
}
.padding-1{
    padding:10px !important;
    }
.bgimage{
    background-image: url('../../../../assests/images/bgimage.png');
    position: absolute !important;
    padding: 40px !important;
    margin-top: 40px !important;
    width: 100% !important;
}
.padding2{
    padding:20px !important;
}
.padding-0{
    padding:0px !important;
}
.margin-0{
    margin:0px !important;
}
.padding-2{
    padding:20px !important;
    height: 150px !important;
}
.padding-3{
    padding:20px !important;
}
.color-white{
    color:#fff !important;
}
.clear-fix{
    clear:both !important;
}
.img-responsive{
    display: block;
    max-width: 100%;
    height: auto;
}
/* watch lovers component  start*/
.border-middle{
    border: 1px solid #BC8960 !important;
    width: 190px !important;
    text-align: center !important;
    margin: 10px auto !important;
}
.bg-card-watch-lovers-color{
    background: #383838 !important;
    color:#fff !important;
    width: 286px !important;
}
.heading-watch-lovers{
    color:#383838 !important;
    font-weight: 600 !important;
}
/* watch lovers component end*/

/* bg-card-signin-subscribe-color  start */
.center-margin{
    margin:0 auto !important;
    text-align: center !important;
}
.signinsubscribe-button-padding{
    padding:10px 40px !important;
}
.Signinsubscribe-card{
    width: 70% !important;
    margin:0 auto  !important;
    border-radius:25px !important;
}
.bg-card-signin-subscribe-color{
    background: #CEA05F !important;
    color:#fff !important;
}
.bg-card-signin-subscribe-color1{
    background: #fff !important;
    color:#383838 !important;
}
.Divider-card-signin-subscribe{
    width: 120px !important;
    margin: 0 auto !important;
    background: #fff !important;
}
.Divider-card-signin-subscribe1{
    width: 120px !important;
    margin: 0 auto !important;
    background: #383838 !important;
}
.small-0{
    font-size: 12px !important;
}
.Button-signingSubscribe{
    background: transparent linear-gradient(90deg, #FFFFFF 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #CAAB74;
    border: 1px solid #FFFFFF;
    opacity: 1;
    width: 200px;
    border-radius: 25px !important;
    color:#9F7C50 !important;
    font-size:16px !important;
    font-weight:600;
}
.Button-signingSubscribe2{
    background: transparent linear-gradient(90deg, #CEA05F 0%, #E9B56B 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #CAAB74;
    border: 1px solid #FFFFFF;
    opacity: 1;
    width: 200px;
    border-radius: 25px !important;
    color:#fff !important;
    font-size:16px !important;
    font-weight:600;
}
/* .Button-signingSubscribe :hover{
    background: transparent linear-gradient(90deg, #9F7C50 0%, #CAAB74 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #CAAB74;
    border: 1px solid #FFFFFF;
    opacity: 1;
    width: 200px;
    border-radius: 25px !important;
    color:#fff !important;
    font-size:18px !important;
    font-weight:600;
} */
/* bg-card-signin-subscribe-color end */


.img-large{
    width:80px !important;
    height: 80px !important;
    margin:0 auto !important;
}
.bg-color{
    background: #E7D0A8 !important;
}
.img-width{
    width: 150px !important;
    margin:0 auto !important;
}

/* bg-color-1-how-it-work start */
.bg-color-1-how-it-work{
    background: #383838 !important;
    width:50px !important;
    height: 50px !important;
    font-size: 32px !important;
    color: #fff !important;
    border-radius: 25px !important;
    text-align: center !important;
    margin: 0 auto !important;
}
.bg-color-2-how-it-work{
    background:#CEA05F !important;
    width:50px !important;
    height: 50px !important;
    font-size: 32px !important;
    color: #000 !important;
    border-radius: 25px !important;
    text-align: center !important;
    margin: 0 auto !important;
}
.bg-color-3-how-it-work{
    background:#CEA05F !important;
    width:50px !important;
    height: 50px !important;
    font-size: 32px !important;
    color: #fff !important;
    border-radius: 25px !important;
    text-align: center !important;
    margin: 0 auto !important;
}

/* signin-subscribe start */
.signin-subscribe-bg{
    background: #241332 0% 0% no-repeat padding-box url('../../../../assests/images/Group\ 1601.png');
    height:90vh;
    background-size: cover;
    opacity: 1;
    backdrop-filter: blur(9px);
    -webkit-backdrop-filter: blur(9px);
}

/* Form-subscribe start */
.Form-subscribe{
    margin: 0px 30px;
}


/* responsiveness home */
@media only screen and (max-width:1024px)
{
    .height-25{
        height: 70vh !important;
    }
    .padding2{
        padding:10px !important;
    }
    .position-text-banner{
        margin:0px 0px !important;
    }
    .position-text-banner1{
        position: relative !important; 
        top: 6% !important;
        padding: 0px 0px !important;
        margin: 0px 0px !important;
        color: #fff !important;
    }
    .bg-card-watch-lovers-color{
        background: #383838 !important;
        color: #fff !important;
        width: 246px !important;
        margin:0 auto !important;
    }
    .bg-card-watch-lovers-color{
        width:100% !important;
    }
    .text-wrap{
        font-size:16px !important;
    }
}
@media only screen and (max-width:1079px){
    /* .bg-card-signin-subscribe-color1{
        width: 195px !important;
        height:375px !important;
    } */
    .Button-signingSubscribe2,.Button-signingSubscribe{
        width:135px !important;
        font-size:16px !important;
    }
}
@media only screen and (max-width:959px){
    /* .bg-card-signin-subscribe-color1{
        width: 100% !important;
        height:100% !important;
    } */
    .Button-signingSubscribe2{
        font-size:16px !important;
    }
    .alignmen-5{
        width:50% !important;
        margin: 0 auto !important;
    }
}
@media only screen and (max-width:600px){
.bg-card-signin-subscribe-color{
    margin:0 auto !important;
    width: 297px !important;
}
/* .bg-card-signin-subscribe-color1{
    margin:10px 0px !important;
    width: 297px !important;
} */
.bg-card-watch-lovers-color,.bg-card-signin-subscribe-color,.bg-card-signin-subscribe-color1{
    width:100% !important;
}
.position-text-banner1{
    position: relative !important; 
    top: 35px !important;
    padding: 0px 0px !important;
    margin: 0px 0px !important;
    color: #fff !important;
}
.signin-subscribe-bg{
    height:auto !important;
}
.height-25{
    height: 85vh !important;
}
.position-text-banner {
    position: absolute;
    top: 5% !important;
    padding: 0px 15px !important;
    margin: 0px 0px !important;
    border-left: 4px solid #fff;
    color: #fff !important;
    width: 310px !important;
}
.button-slider1{
    margin: 5px 0px !important;
}
.alignment-2{
    width:280px !important;
    text-align: justify !important;
    text-overflow: ellipsis !important;

}
.Signinsubscribe-card{
    width:100% !important;
}
.Form-subscribe{
    margin: 0 20px !important;
}
.footermain{
    height: auto !important;
}
.Footer-social-icons{
    text-align: center !important;
    float:unset !important;
}
.img-resonsive{
    height: 90vh !important;
}
.alignment-3{
    text-align: center !important;
    font-size: 18px !important;
}
}
.alignment-0{
    margin:0 auto !important;
    text-align: center !important;
}
.alignment-3{
    text-align: center !important;
    font-size: 18px !important;
}
.alignment-4{
    width:100% !important;
}
.MuiTypography-colorPrimary{
    color:#9F7C50;
    text-decoration: none !important;
}
a{
    text-decoration: none !important;
}
.login-0{
    padding: 0px 10px !important;
    color: #fff !important;
}

/* image alignment */
.fix-text-width{
    text-align: center !important;
}
.primary{
    color: #9F7C50 !important;
}
.primary-bg{
    background: #CEA05F !important;
}
.disable-bg {
    background: rgba(0, 0, 0, 0.26) !important;
}
.MuiTypography-colorPrimary{
    color: #9F7C50 !important;
}
.img-responsive1{
    display: block;
    width: 100%;
    height: auto;
}
.img-responsive11{
    display: block;
    width: 100%;
    height: 100%;
    /*height: auto; */
    border-width: 2px;
    border-radius: 1rem;
    border-style: dashed;
    border-color: #cbd5e1;
    background-color: #f8fafc;
    padding: 5px;
}
.space-left-10{
    padding-left:10px !important;
    text-transform: capitalize !important;
}
.space-top-10{
    padding-top:10px !important;
}
.space-bottom-10{
    padding-bottom:10px !important;
}
.space-right-10{
    padding-right:10px !important;
}
.space-left-15{
    padding-left:15px !important;
}
.space-top-15{
    padding-top:15px !important;
}
.space-bottom-15{
    padding-bottom:15px !important;
}
.space-right-15{
    padding-right:15px !important;
}
.space-left-20{
    padding-left:20px !important;
}
.space-top-20{
    padding-top:20px !important;
}
.space-bottom-20{
    padding-bottom:20px !important;
}
.space-right-20{
    padding-right:20px !important;
}
.space-left-30{
    padding-left:30px !important;
}
.space-top-30{
    padding-top:30px !important;
}
.space-bottom-30{
    padding-bottom:30px !important;
}
.space-right-30{
    padding-right:30px !important;
}
.space-left-40{
    padding-left:40px !important;
}
.space-top-40{
    padding-top:40px !important;
}
.space-bottom-40{
    padding-bottom:40px !important;
}
.space-right-40{
    padding-right:40px !important;
}
.space-left-50{
    padding-left:50px !important;
}
.space-top-50{
    padding-top:50px !important;
}
.space-bottom-50{
    padding-bottom:50px !important;
}
.space-right-50{
    padding-right:50px !important;
}
.border-0{
    border-bottom:2px solid #D2D2D2 !important;
}
.border-bottom-1{
    border-bottom:1px solid #D2D2D2 !important;
}
.border-middle6 {
    border: 1px solid #BC8960 !important;
    width: 90px !important;
    text-align: center !important;
    margin: 10px auto !important;
}

.text-transform{
    text-transform: capitalize !important;
}
.MuiFormControl-root {
    border: 0;
    margin: 0;
    /* display: inline-flex; */
    padding: 0;
    position: relative;
    min-width: 0;
    flex-direction: column;
    vertical-align: top;
}
.MuiGrid-spacing-xs-4 {
    width: calc(100% + 32px);
    margin: -40px;
}